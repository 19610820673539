import { collection, doc, getDoc, addDoc, setDoc } from "firebase/firestore"; 
import { getFirestore } from "firebase/firestore";


export class User
{
    uid:string;
    email:string|null;
    displayName: string|null;
    photoUrl: string|null;

    constructor(uid:string, email:string|null, displayName:string|null, photoUrl:string|null){
        this.uid = uid;
        this.email = email;
        this.displayName = displayName;
        this.photoUrl = photoUrl;
    }
}

export async function validate_username(user:User)
{
    let db = getFirestore();
    const docRef = doc(db, "users", user.uid);
    const docSnap = await getDoc(docRef);
    let {uid, ...userObj} = Object.assign(user);
    if(!docSnap.exists()){
        if(user.displayName == null && user.email){
            user.displayName = user.email.split('@')[0];
            userObj.displayName = user.displayName;
        }
        await setDoc(docRef, userObj);
    }else{
        user = Object.assign(user, docSnap.data());
    }
    return user;
}