import { wasi } from './wasi';
import { mw, MInstance, RunResult } from './mw';
import { initZoom, Zoom } from './zoom';
import { App, get_app, signin, signout, showSigninUI } from './app';
import { CodeJar } from 'codejar';
import {withLineNumbers} from 'codejar/linenumbers';
import { draw_surface } from './webgpu/main';
import { validate_username, User } from './user';

var instance:MInstance;
var runResult:RunResult;
var zoom: Zoom | null = null;
var jar:CodeJar | null = null;
var app:App | null = null;


function set_image_data(data:any, width:number, height:number)
{
    let canvasDom = document.getElementById(instance.canvas_id) as HTMLCanvasElement;
    if(!canvasDom) return;
    let ctx = canvasDom.getContext('2d');
    if(!ctx) return;
    canvasDom.width = width;
    canvasDom.height = height;
    let imageData = ctx.createImageData(width, height);
    imageData.data.set(data);
    ctx.putImageData(imageData, 0, 0);
}

mw(wasi(), '/m.wasm', print, true, set_image_data).then(
    result=>{
        instance = result;
        print_version("version", instance.version);
        init_code();
    }
);

function init_code()
{
    const highlight = (editor:HTMLElement)=>{
        //console.log("input: ", editor.textContent?.length);
        //console.log(JSON.stringify(editor.textContent));
        let highlighted_code = instance.highlight_code(editor.textContent);
        //console.log("output: ", highlighted_code.length);
        //console.log(JSON.stringify(highlighted_code));
        editor.innerHTML = highlighted_code;
    };
    let code_editor = document.getElementById('code-editor');
    if(code_editor){
        jar = CodeJar(code_editor, withLineNumbers(highlight));
        // Update code
        var code_text = 
        `//color function returns (r, g, b) tuple based on iteration count and distance
def color(iter_count:int, iter_max:int, sq_dist:f64):
    let mut v = 0.0, r = 0.0, g = 0.0, b = 0.0
    if iter_count < iter_max:
        v = (log(iter_count+1.5-(log2((log(sq_dist))/2.0))))/3.4
        if v < 1.0:
            r = v ** 4;g = v ** 2.5;b = v
        else:
            v = v < 2.0 ? 2.0 - v : 0.0
            r = v;g = v ** 1.5;b = v ** 3.0
    ((u8)(r * 255), (u8)(g * 255), (u8)(b * 255))

/* main plot function
x0, y0: coordinate value of top left
x1, y1: coordinate value of bottom right
*/
def plot_mandelbrot_set(x0:f64, y0:f64, x1:f64, y1:f64):
    print("plot area: x0:%f y0:%f x1:%f y1:%f\\n", x0, y0, x1, y1)
    let width = 400, height = 300
    let mut img:u8[height][width * 4]
    let scalex = (x1-x0)/width, scaley = (y1-y0)/height, max_iter = 510
    for x in 0..width:
        for y in 0..height:
            let cx = x0 + scalex*x
            let cy = y0 + scaley*y
            let mut zx = 0.0, zy = 0.0
            let mut zx2 = 0.0, zy2 = 0.0
            let mut n = 0
            while n<max_iter and (zx2 + zy2) < 4.0:
                zy = 2.0 * zx * zy + cy
                zx = zx2  - zy2 + cx
                zx2 = zx * zx
                zy2 = zy * zy
                n++
            let cr, cg, cb = color(n, max_iter, zx2 + zy2)
            img[y][4*x] = cr
            img[y][4*x+1] = cg
            img[y][4*x+2] = cb
            img[y][4*x+3] = 255

    setImageData(img, width, height) /*call js to set img data on canvas*/
// call main plot function
plot_mandelbrot_set(-2.0, -1.2, 1.0, 1.2)
    `;
        instance.text_id = code_editor.dataset.text || '';
        jar.updateCode(code_text);
    } else {
        code_editor = document.getElementById('webgpu-code-editor');
        if(code_editor){
            jar = CodeJar(code_editor, withLineNumbers(highlight));

        }
    }
}

function run(code_id:string)
{
    // if(app && !app?.auth.currentUser && code_id != "code-editor"){
    //     signin(app);
    //     return;
    // }
    let result_text_id = code_id + "_result";
    let result_graph_id = code_id + "_graph_result";
    clear(result_text_id, result_graph_id);
    if(runResult != undefined && runResult.code_bytes != undefined){
        const free = instance.mw_instance.exports.free as CallableFunction;
        free(runResult.code_bytes);
    }
    var code_string:any;
    if(jar){
        code_string = jar.toString();
    }else{
        code_string = (document.getElementById(code_id) as HTMLInputElement).value;
    }
    instance.canvas_id = result_graph_id;   
    instance.text_id = result_text_id;     
    runResult = instance.run_code(code_string, false);
    print(runResult.start_result);
    if(!zoom) return;
    zoom.init();
    update_back_forward();
}

function update_back_forward()
{
    if(!zoom) return;
    (document.getElementById('backward') as HTMLButtonElement).disabled = zoom.cur_pos <= 0;
    (document.getElementById('forward') as HTMLButtonElement).disabled = zoom.cur_pos >= zoom.history.length - 1;
}
        
function print(text:string){
    let textDom = document.getElementById(instance.text_id);
    if(!textDom){
        console.log("missing textDom: ", instance.text_id);
        return;
    }
    if(text!=undefined && text != null)
        textDom.textContent += text;
}

function clear(result_text_id:string, result_canvas_id:string){
    let textDom = document.getElementById(result_text_id);
    let canvasDom = document.getElementById(result_canvas_id) as HTMLCanvasElement;
    if(textDom)
        textDom.textContent = '';
    if(!canvasDom) return;
    const ctx = canvasDom.getContext('2d');
    if(!ctx) return;
    ctx.clearRect(0, 0, canvasDom.width, canvasDom.height);
}

function print_version(version_id:string, version:string) {
    let versionDom = document.getElementById(version_id);
    if(!versionDom) return;
    versionDom.textContent = version;
}

window.onload = function() {
    app = get_app(onUserChange);
    var user_link = document.getElementById("user");
    if (user_link){
        user_link.onclick = () => {
            if(!app) return;
            if(app.auth.currentUser){
                window.location.href = '/user/profile.html';
            }else{
                signin(app);
            }
        };
    }
    draw_surface();
    
    var runs = document.querySelectorAll("button[data-run]");
    runs.forEach((runBtn)=>{
        let runDom = runBtn as HTMLElement;
        runDom.addEventListener("click", 
        event => {
            run(runDom.dataset.run || '');
        });
    });
    

    var backward = document.getElementById("backward") as HTMLButtonElement;
    if(backward){
        backward.addEventListener('click', 
            function ()
            {
                if(!zoom) return;
                zoom.backward();
            });
    }
    var forward = document.getElementById("forward") as HTMLButtonElement;
    if(forward){
        forward.addEventListener('click', 
            function ()
            {
                if(!zoom) return;
                zoom.forward();
            }
        );
    }
    function onZoom(x0:number, y0:number, x1:number, y1:number)
    {
        const plot_mandelbrot_set = runResult.code_instance.exports.plot_mandelbrot_set as CallableFunction;
        plot_mandelbrot_set(x0, y0, x1, y1);
        update_back_forward();
    }

    let canvas_container = document.getElementById('canvas_container');
    if(canvas_container)
        zoom = initZoom(canvas_container, onZoom, -2.0, -1.2, 1.0, 1.2, 400, 300);
    if(backward && forward)
        update_back_forward();

    function setUser(user:User)        
    {
        if(!app) return;
        let signout_link = document.getElementById('signout');
        if(user_link){
            if(user.photoUrl){
                user_link.innerHTML = `<img src="${user.photoUrl}" style="vertical-align: middle;width:35px;height:35px;border-radius: 50%;">`;
            }else if (user.displayName){
                user_link.innerHTML = user.displayName;
            }else if(user.email){
                user_link.innerHTML = user.email;
            }
            user_link.style.borderStyle = "none";
            let avatar = document.getElementById('avatar');
            if(avatar && app.auth.currentUser && app.auth.currentUser.photoURL){
                (avatar as HTMLImageElement).src = app.auth.currentUser.photoURL;
            }
            let displayname = document.getElementById('displayname');
            if(displayname){
                if(user.displayName)
                    displayname.textContent = user.displayName;
                else{
                    displayname.textContent = user.email;
                }
            }
            if(signout_link){
                signout_link.addEventListener('click', ()=>{
                    if(app) signout(app);
                });
            }
        }
    }
    //profile page
    function onUserChange(user:User){
        if(!app) return;
        let signout_link = document.getElementById('signout');
        if(user_link){
            if(user){
                validate_username(user).then(
                    (result)=>{
                        setUser(result);
                    }
                );
            }else{
                user_link.innerHTML = "Sign in";
                user_link.style.borderStyle = "solid";
                user_link.style.borderRadius = "5px";
                if(signout_link){
                    window.location.href = '/';
                }
            }
        }
        if(document.getElementById('firebaseui-auth-container')){
            if(!user){
                showSigninUI(app);
            }else{
                window.location.href = '/user/profile.html';
            }
        }
    }
};
